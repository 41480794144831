.playground aside .side-button:nth-child(1)::after { transform: translate(calc(1 * var(--offset))); }
.playground aside .side-button:nth-child(2)::after { transform: translate(calc(2 * var(--offset))); }
.playground aside .side-button:nth-child(3)::after { transform: translate(calc(3 * var(--offset))); }
.playground aside .side-button:nth-child(4)::after { transform: translate(calc(4 * var(--offset))); }
.playground aside .side-button:nth-child(5)::after { transform: translate(calc(5 * var(--offset))); }
.playground aside .side-button:nth-child(6)::after { transform: translate(calc(6 * var(--offset))); }
.playground aside .side-button:nth-child(7)::after { transform: translate(calc(7 * var(--offset))); }
.playground aside .side-button:nth-child(8)::after { transform: translate(calc(8 * var(--offset))); }
.playground aside .side-button:nth-child(9)::after { transform: translate(calc(9 * var(--offset))); }
.playground aside .side-button:nth-child(10)::after { transform: translate(calc(10 * var(--offset))); }
.playground aside .side-button:nth-child(11)::after { transform: translate(calc(11 * var(--offset))); }
.playground aside .side-button:nth-child(12)::after { transform: translate(calc(12 * var(--offset))); }
.playground aside .side-button:nth-child(13)::after { transform: translate(calc(13 * var(--offset))); }
.playground aside .side-button:nth-child(14)::after { transform: translate(calc(14 * var(--offset))); }
.playground aside .side-button:nth-child(15)::after { transform: translate(calc(15 * var(--offset))); }

.playground aside:hover .side-button::after {
  transform: translate(0);
  transition: transform .4s;
}
