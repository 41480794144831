article pre {
  max-width: 300px;
  margin: 0 auto;
  padding: 10px 30px;
  background-color: #333;
  color: wheat;
  border-radius: 3px;
}

.badge {
  background-color: red;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
}

.referral-info {
  background-color: steelblue;
  text-decoration: wavy;
  font-size: large;
  font-weight: bolder;
  color: white;
  text-align: center;
  border-radius: 5px;
}

.enterprise-sales {
  background-color: black;
  text-decoration: wavy;
  font-size: large;
  font-weight: bold;
  color: white;
  text-align: center;
  border-radius: 5px;
}

.bonus-30 {
  background-color: seagreen;
  text-decoration: wavy;
  font-size: large;
  font-weight: bold;
  color: white;
  text-align: center;
  border-radius: 5px;
}
