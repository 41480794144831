.layout::-webkit-scrollbar {
  display: none;
}

.borderless {
  border: unset;
}

.shadowless {
  box-shadow: unset;
}

.vertPad7px>.rc-menu-submenu-title {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.fontBold {
  font-weight: bold;
}

.fontLighter {
  font-weight: lighter;
}
