:global .CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  width: 100%;
  height: 100%;
  direction: ltr;
}

.editor {
  width: 100%;
  height: 100%;
}
