@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700");

.widget-name {
  font-size: 4rem;
  background: beige;
  padding: 15px;
}

.widget-desc {
  padding: 15px;
  background: rgb(164, 164, 138);    
}

.widget-is-container {
  padding: 15px;
  background: whitesmoke;
}

.property-title {
  padding: 50px 15px 20px 15px;
  background: white;
  color: burlywood;
}

.property-name {
  font-size: 3rem;
  background: antiquewhite;
  padding: 15px;
  margin-top: 50px;
}

.property-desc {
  padding: 15px;
  background: aliceblue;
}  

.property-enum-value {
  padding: 15px;
  background: rgb(126, 138, 149);
}

.table-container-DEAD {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.table-container {
  display: flex;
}

.sample-container {
  width: 100%;
  border: 4px solid;
  border-color: black;
}

.code-container {
  width: 100%;
  height: calc(100vh - 56px);
  overflow-y: hidden;
  display: flex;
}

.property-sample-container {
  margin: 0 10% 0 10%;
  padding: 5px 5px 0px 5px;
  border: 2px solid;
  border-color: rgb(225, 247, 127);
  background-color: lightyellow;
}

.property-code-container-header {
  margin: 0 0 0 30px;
}

.property-code-container {
  padding: 10px;
  margin: 5px 0 5px 30px;
  /* width: 100%; */
  border: 1px dashed;
  border-color: black;
  background-color: lightgray;
}

.attribute-title {
  padding: 50px 15px 20px 15px;
  background: white;
  color: rgb(122, 178, 126);
}

.attribute-sample-container {
  margin: 0 10% 0 10%;
  padding: 5px 5px 0px 5px;
  border: 2px solid;
  border-color: rgb(225, 247, 127);
  background-color: lightgreen;
}

.attribute-code-container-header {
  margin: 0 0 0 30px;
}

.attribute-code-container {
  padding: 10px;
  margin: 5px 0 5px 30px;
  /* width: 100%; */
  border: 1px dashed;
  border-color: black;
  background-color: lightgray;
}

.code-container > aside {
  width: 72px;
  --offset: -72px;
  padding-top: 10px;
  background-color: #222;
  color: #fff;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.table {
  width: 100%;
  border: 1px solid #EEEEEE;
}

.table-header {
  display: flex;
  width: 100%;
  color: aqua;
  background: #000;
  padding: 18px 0;
}

.table-row {
  display: flex;
  width: 100%;
  padding: 8px 0;
}
.table-row:nth-of-type(odd) {
  background: #EEEEEE;
}

.table-data, .header__item {
  flex: 1 1 20%;
  text-align: center;
}

.header__item {
  text-transform: uppercase;
}

.filter__link {
  color: white;
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding-left: 24px;
  padding-right: 24px;
}
.filter__link::after {
  content: '';
  position: absolute;
  right: -18px;
  color: white;
  font-size: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.filter__link.desc::after {
  content: '(desc)';
}
.filter__link.asc::after {
  content: '(asc)';
}
