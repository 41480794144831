/* @page {
  size: 21cm 29.7cm;
  margin: 30mm 45mm 30mm 45mm;
} */
@media print {
  body {
    width: 21cm;
    height: 29.7cm;
    margin: 30mm 45mm 30mm 45mm;
  }
}

div.chapter, div.appendix {
  page-break-after: always;
}