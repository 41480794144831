.sliding-background {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="712px" height="1em" stroke="yellow" stroke-width="0.2px"><text x="5" y="6" font-style="italic" font-size-adjust="" font-family="Times, serif" font-size="30%" >मेरी भाषा में प्रोग्रामिंग എന്റെ ഭാഷയിൽ പ്രോഗ്രാമിംഗ് ನನ್ನ ಭಾಷೆಯಲ್ಲಿ ಪ್ರೋಗ್ರಾಮಿಂಗ್ माझ्या भाषेत प्रोग्रामिंग میری زبان میں پروگرامنگ. Programming in my language Programmieren in meiner Sprache Programmer dans ma langue 私の言語でプログラミングする 用我的语言编程 Programmeren in mijn taal การเขียนโปรแกรมในภาษาของฉัน</text></svg>');
  background-size: cover;
  /* background-attachment: fixed; */
  background-blend-mode: normal;
  /* height: 560px;
  width: 5076px; */
  animation: dslide 60s linear infinite;
  font-size: xxx-large;
}
  
@keyframes dslide {
    0% {
      /* transform: translate3d(0, 0, 0); */
      background-position: 0 0;
    }
    100% {
      /* transform: translate3d(-1692px, 0, 0); */
      background-position: 100% 0;
    }
}

.color_violet {
  color: violet;
}
.color_indigo {
  color: indigo;
}
.color_blue {
  color: blue;
}