/* Here to put css resets and imports */

html {
  font-size: 12px;
}

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

body div#root {
  margin: 0;
  padding: 0;
  /*
  font-family: var(--font-family-monospace);
  */
  font-size: 12px;
  font-weight: normal;
}

/* Generic styles */

.hidden {
  display: none;
}

pre {
  text-align: left;
}

.overlay {
  /* display: flex; */
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  z-index: 10000;
}

.panel {
  max-width: 400px;
  text-align: center;
  margin: 0 auto;
  padding: 100px 0 0 0;
}
